import styled from 'styled-components';

export const Container = styled.div`
    padding: 26px;
`

export const WrapperLeft = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const WrapperRight = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const P = styled.p`
    line-height: 1.8rem;
    font-weight: 300;
    letter-spacing: 1.2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    @media (min-width: 1280px) {
        width: 70%;
    }
`
