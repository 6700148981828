import React from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo"
import { Container, WrapperLeft, WrapperRight, P } from '../styles/pages/ueber-diewohnplaner';
import { Headline, Subline } from '../styles/components/typography';


const UeberUns = () => {
    return (
        <Layout>
             <SEO title="Über uns" />
            <Container>
                <Headline>Ihr Einrichtungshaus in Düsseldorf – Design Möbel für kreatives Wohnen</Headline>
                <WrapperRight>
                    <P> Die neue Wohnung ist schön geschnitten und bestimmt gut einzurichten. Sie haben schon viele Ideen, wie einige alte Möbel zu stellen sind und welcher Art die neuen Möbel sein sollen. Aber ein durchgehendes Konzept für die Gestaltung der Räume will sich einfach nicht zusammenfügen? Einige Möbel finden sie sehr schön, sie passen jedoch nicht so recht zu weiteren Möbeln, die Ihnen ebenfalls vorschweben, und über die Platzierung im Raum sind Sie sich noch nicht sicher?</P>
                </WrapperRight>
                <iframe width="930" height="515" src="https://www.youtube.com/embed/0NBCyixEMHA" title="Unser Konzept" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <Subline>Kreativ Wohnen - Exklusive Design Möbel perfekt zur Geltung bringen</Subline>
                <WrapperRight>
                    <P> Diewohnplaner im Einrichtungshaus Düsseldorf können Ihnen nicht nur eine tolle Auswahl außergewöhnlicher Design Möbel, Ecksofas, Schränke, Regale, Sessel und Wohnaccessoires zeigen, sondern helfen Ihnen auch gerne bei der Einrichtungsplanung. Wir helfen Ihnen, moderne Wohntrends oder klassische Raumgestaltungen modern umzusetzen, um kreativ zu wohnen. Unsere Einrichtungsplaner zeigen Ihnen Konzepte, die zu Ihren Wohnvorstellungen sowie zu Ihren Vorlieben in Bezug auf Farben und Formen passen. In unserem Einrichtungshaus und Wohn-Planungsbüro in Düsseldorf zeigen wir Ihnen, wie Sie kreativ wohnen und eine moderne Raumgestaltung individuell umsetzen können.</P>
                </WrapperRight>
                <WrapperLeft>
                    <P> Zu unserem Einrichtungshaus Düsseldorf gehört auch eine eigene Tischlerei. So können wir auch außergewöhnliche Möbelideen für individuelles Design umsetzen. Wir können Ecksofas, Regale, Tische sowie ein Sofa mit Stoff oder Leder genau nach Ihren Maßen und persönlichen Vorstellungen gestalten. </P>
                </WrapperLeft>
                <WrapperRight>
                    <P> Um kreativ wohnen zu können, sind Ihnen bei uns keine Grenzen gesetzt. In unserem Einrichtungshaus in Düsseldorf können Sie Design Möbel wählen sowie Möbel passend für Ihre Wohnung gestalten lassen. Vom Sofa Stoff für Ecksofas bis hin zu Raumtextilien und der passenden Beleuchtung beraten wir Sie gerne ausführlich. Gern entwerfen unsere erfahrenen Planer für Sie ein vollständiges, individuelles Wohnkonzept. Ebenso gern sind wir bereit, Sie in Düsseldorf bei der Neugestaltung einer vorhandenen Einrichtung oder der Ergänzung durch einige ausgefallene Design Möbel zu unterstützen.</P>
                </WrapperRight>
                <WrapperLeft>
                    <P> Auch mit nur einigen Veränderungen können Sie bereits kreativ wohnen und neue Trends umsetzen. Wir zeigen Ihnen, wo und wie schicke Ecksofas, das kleine Sofa mit Stoff, exklusive Regale und schlichte Massivholztische die verschiedensten Räume optisch verändern können. Um kreativ zu wohnen, sind oft weniger Design Möbel und Accessoires nötig, als Sie denken. Hier kommt es auf die richtige Platzierung und Harmonie an.</P>
                </WrapperLeft>
                <WrapperRight>
                    <P> Ob puristisches Wohnkonzept, rustikale Einrichtung mit Massivholzmöbeln oder Sofa Stoff im Landhausstil – im Einrichtungshaus beraten wir Sie gerne individuell und persönlich. Wir helfen Ihnen, Ihre Wünsche so perfekt wie möglich umzusetzen. Kreativ wohnen können Sie in jedem Einrichtungsstil. Es ist oft nur eine Frage, wie dieser Wohnstil mit Ihrer persönlichen Note umgesetzt wird.</P>
                </WrapperRight>
                <Subline>Ihre Auswahl für Design Möbel, Accessoires und Leuchten im Einrichtungshaus Düsseldorf</Subline>
                <WrapperLeft>
                    <P> In unserem ausgewähltem Möbelangebot in Düsseldorf finden Sie Schlafzimmermöbel, Tische, Stühle, Ecksofas sowie Raumtextilien vom Teppich bis zum Sofa Stoff, Küchen, Leuchten und kleine, dekorative Kunstwerke. Für Ecksofas, Sessel und kleine Sofas können Sie unter vielfältigen Möglichkeiten Ihren Sofa Stoff wählen. Gern helfen wir Ihnen, individuelle Design Möbel zu entwerfen, die Wohnlichkeit und Funktionalität verbinden, um kreativ zu wohnen.</P>
                </WrapperLeft>
                <WrapperRight>
                    <P> Sie können Schränke, Regale und Ecksofas auswählen, und in unserer Werkstatt individuell anpassen lassen. Auch Sondermaße und der ausgefallenste Sofa Stoff sowie futuristische Sessel sind kein Problem. Gern können Sie sich im Einrichtungshaus Düsseldorf auch in unserem Sale Bereich umsehen. Hier finden Sie extravagante Accessoires, Statuen, Vasen, Lampen, Ecksofas sowie kleine Sofa mit Stoff oder Leder zu exklusiven Sonderpreisen. Hier können Sie glanzvolle Schnäppchen für außergewöhnliche Design Möbel und traumhaft schöne Leuchten und Accessoires für die kreative Gestaltung Ihres Heims finden. Holen Sie sich Anregungen für individuelle Wohnideen in unserer Fotogalerie sowie bei der Vorstellung unserer verschiedenen Produkte oder besuchen Sie gleich unser Einrichtungshaus und Planungsbüro mit individueller Möbelausstellung in Düsseldorf.</P>            </WrapperRight>

            </Container>
        </Layout>
    );
}

export default UeberUns;